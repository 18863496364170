.profile {
  width: 100%;
  margin-top: 20px;
  margin-bottom: auto;
}

.profile__data {
  font-size: 20px;
  cursor: pointer;
}

.profile__data-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile__data-set {
  font-weight: 600;
}